  <template>
  <div>
    <br />
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-relation-to-one
        :label="fields.project.label"
        :readPermission="fields.project.readPermission"
        :navigatePermission="fields.project.navigatePermission"
        :url="fields.project.viewUrl"
        :value="presenter(record, 'project')"
      ></app-view-item-relation-to-one>

      <app-view-item-relation-to-many
        :label="fields.faculty.label"
        :readPermission="fields.faculty.readPermission"
        :navigatePermission="fields.faculty.navigatePermission"
        :url="fields.faculty.viewUrl"
        :value="presenter(record, 'faculty')"
      ></app-view-item-relation-to-many>

      <!--     <app-view-item-relation-to-many
        :label="fields.projectReleaseRequest.label"
        :readPermission="fields.projectReleaseRequest.readPermission"
        :navigatePermission="fields.projectReleaseRequest.navigatePermission"
        :url="fields.projectReleaseRequest.viewUrl"
        :value="presenter(record, 'projectReleaseRequest')"
      ></app-view-item-relation-to-many>
 -->
      <app-view-item-file
        :label="fields.signedDocument.label"
        :value="presenter(record, 'signedDocument')"
          :permissions="fields.signedDocument.permissions"
      ></app-view-item-file>

      <app-view-item-text
        :label="fields.numberOfRequests.label"
        :value="presenter(record, 'numberOfRequests')"
      ></app-view-item-text>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectReleaseModel } from '@/modules/project-release/project-release-model';

const { fields } = ProjectReleaseModel;

export default {
  name: 'app-project-release-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectRelease/view/record',
      loading: 'projectRelease/view/loading',
      projectReleaseId: 'project/form/projectReleaseId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectReleaseId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectRelease/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectReleaseModel.presenter(record, fieldName);
    },
  },
};
</script>
