import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { GenericModel } from '@/shared/model/generic-model';
import { FacultyField } from '@/modules/faculty/faculty-field';
import IntegerField from '@/shared/fields/integer-field';
import FilesField from '@/shared/fields/files-field';
import { ProjectReleaseRequestField } from '@/modules/project-release-request/project-release-request-field';
import { ProjectField } from '@/modules/project/project-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.projectRelease.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.projectRelease.enumerators.${name}.${value}`);
}
const fields = {
  id: new IdField('id', label('id')),

  project: ProjectField.relationToOne(
    'project',
    label('project'),
    {},
  ),
  faculty: FacultyField.relationToMany('faculty', label('faculty'), {
    required: true,
  }),
  projectReleaseRequest: ProjectReleaseRequestField.relationToMany(
    'projectReleaseRequest',
    label('projectReleaseRequest'),
    {
      required: true,
    },
  ),
  signedDocument: new FilesField(
    'signedDocument',
    label('signedDocument'),
    Storage.values.projectReleaseSignedDocument,
    Permissions.getFilePermissions(
      'projectReleaseFileFields',
      'signedDocument',
    ),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      { id: 'created', label: enumeratorLabel('status', 'created') },
      {
        id: 'rejected',
        label: enumeratorLabel('status', 'rejected'),
      },
      {
        id: 'released',
        label: enumeratorLabel('status', 'released'),
      },
      {
        id: 'releaseRequested',
        label: enumeratorLabel('status', 'releaseRequested'),
      },
      {
        id: 'revisionRequested',
        label: enumeratorLabel('status', 'revisionRequested'),
      },
      {
        id: 'vRUploadSignedDocuments',
        label: enumeratorLabel('status', 'vRUploadSignedDocuments'),
      },
      {
        id: 'completed',
        label: enumeratorLabel('status', 'completed'),
      },
    ],
    {},
  ),

  numberOfRequests: new IntegerField(
    'numberOfRequests',
    label('numberOfRequests'),
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectReleaseModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
